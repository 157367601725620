import { useEffect, useState } from 'react';
import { DateToString, ToUtcString, UtcToLocal } from '../../../../helpers/DateHelpers';
import { Http } from '../../../../http';

const LetOutModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    const [isEditDateOut, setIsEditDateOut] = useState(false);
    const [dateOut, setDateOut] = useState('2020-01-01T00:00');
    const [error, setError] = useState('');

    useEffect(() => {
        setError('');
        setIsEditDateOut(false);
        setDateOut(entity?.dateIn ? ToUtcString(UtcToLocal(entity.dateIn)).slice(0, -4) : '');
    }, [entity]);

    const handleSave = async () => {
        setError('');
        if (UtcToLocal(entity.dateIn.slice(0, -3)) > new Date(dateOut)) {
            setError('Дата выезда не может быть меньше даты въезда');
            return;
        } else if (new Date(dateOut) > new Date()) {
            setError('Дата выезда не может быть больше текущей даты');
            return;
        }
        if (entity.entityType === 'guest') {
            await Http.put(`api/guests/${entity.entityId}/auto/reset?dateOut=${dateOut + ':00Z'}`, true);
        } else {
            await Http.put(`api/vehicles/${entity.entityId}/auto/reset?dateOut=${dateOut + ':00Z'}`, true);
        }
        closeHandle(true);
    };

    const changeDateOut = (dtOut) => {
        setDateOut(dtOut);
        setError('');
        console.log('dtOut', dtOut);
        if (UtcToLocal(entity.dateIn.slice(0, -3)) > new Date(dtOut)) {
            setError('Дата выезда не может быть меньше даты въезда');
            return;
        } else if (new Date(dtOut) > new Date()) {
            setError('Дата выезда не может быть больше текущей даты');
            return;
        }
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удаление автомобиля с парковки</h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-2'>
                                <div className='col'>
                                    <h4>
                                        {entity.entityType === 'guest' ? 'Посетитель' : 'Транспорт'}{' '}
                                        {entity.carNumber + entity.carRegion}
                                    </h4>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col'>
                                    <div className='fild-label'>Дата въезда</div>
                                    <div className='fild-text'>
                                        {entity?.dateIn ? DateToString(UtcToLocal(entity.dateIn), true) : ''}
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col'>
                                    <div className='fild-label'>Дата выезда</div>
                                    {isEditDateOut ? (
                                        <div className='d-flex'>
                                            <input
                                                type='datetime-local'
                                                className='form-control'
                                                id='dateStart'
                                                value={dateOut}
                                                onChange={(e) => changeDateOut(e.target.value)}
                                                style={{ width: 'auto' }}
                                                min={ToUtcString(UtcToLocal(entity.dateIn))}
                                                max={ToUtcString(new Date())}
                                            />
                                            <button
                                                className='btn av-text-primary'
                                                onClick={() => changeDateOut(ToUtcString(new Date()).slice(0, -4))}
                                            >
                                                Текущие дата и время
                                            </button>
                                            <button
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2'
                                                onClick={() => setIsEditDateOut(false)}
                                            >
                                                <i className='fas fa-times text-muted' />
                                            </button>
                                        </div>
                                    ) : (
                                        <div className='fild-text'>
                                            {DateToString(new Date(dateOut), true)}
                                            <button
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2'
                                                onClick={() => setIsEditDateOut(true)}
                                            >
                                                <i className='fas fa-pen text-muted' />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {error ? <div className='alert alert-danger'>{error}</div> : null}
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn av-btn-simple' onClick={closeHandle}>
                                Закрыть
                            </button>
                            <button type='button' className='btn av-btn-primary' onClick={handleSave} disabled={error}>
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default LetOutModal;
