import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const initialState = {
    isLoading: true,
    isAuthorize: false,
    isSendCode: false,
    user: [],
    isAuthErrors: false,
    errorDescription: '',
    errorType: '',
    isSavingUser: false,
    isSavingError: false,
    notifyArray: [],
};

export const loginUserAsync = createAsyncThunk('currentUser/loginUser', async (loginData) => {
    // loginData.typeLogin - тип логина
    // 1 - логин по паролю
    // 2 - логин по коду из СМС
    let authUrl = 'login';
    if (loginData.typeLogin === 2) {
        authUrl = 'login_code';
    }
    const loginResult = await Http.post(`api/auth/${authUrl}/`, false, loginData.auth);

    return loginResult;
});

export const getCurrentUserAsync = createAsyncThunk('currentUser/getCurrentUser', async () => {
    const profile = await Http.get('api/profile/', true);

    return profile.result;
});

export const getPhoneCodeAsync = createAsyncThunk('currentUser/getphonecode', async (login) => {
    const codeResult = await Http.post(`api/auth/verification_code`, false, {"phone": login});
    return codeResult;
});

export const saveUserInfo = createAsyncThunk('currentUser/saveUserInfo', async (userInfo, thunkAPI) => {
    const result = await Http.put('api/profile', true, userInfo);
    if (
        result !== undefined &&
        result != null &&
        result.result.login !== undefined &&
        result.result.login != null &&
        result.result.login.length > 0
    ) {
        thunkAPI.dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Пользователь сохранён!',
                description: 'Данные пользователя успешно сохранены!',
                type: 'success',
            })
        );
    } else {
        thunkAPI.dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Ошибка сохранения!',
                description: 'Не удалось сохранить данные пользователя! Перезагрузите страницу и попробуйте снова!',
                type: 'danger',
            })
        );
    }

    //const result = await fetchCount(21);
    return result;
});

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsAuthorize: (state) => {
            state.isAuthorize = true;
        },
        logoutUser: (state) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_time');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_time');
            state.user = [];
            state.isAuthorize = false;
        },
    },
    extraReducers: {
        [loginUserAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUserAsync.fulfilled]: (state, action) => {
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isAuthErrors = true;
                state.errorDescription = action.payload.errorDescription;
                state.errorType = action.payload.error;
                state.isAuthorize = false;
            } else {
                localStorage.setItem('access_token', action.payload.result.access_token.value);
                localStorage.setItem('access_token_time', action.payload.result.access_token.expireAt);
                localStorage.setItem('refresh_token', action.payload.result.refresh_token.value);
                localStorage.setItem('refresh_token_time', action.payload.result.refresh_token.expireAt);
                state.isAuthorize = true;
            }
            state.isLoading = false;
        },
        [loginUserAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.isAuthorize = false;
            state.isAuthErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [getCurrentUserAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getCurrentUserAsync.fulfilled]: (state, action, idn) => {
            state.user = action.payload;
            state.isLoading = false;
        },
        [getCurrentUserAsync.rejected]: (state, action) => {
            console.error('currentUserError', action);
            state.isLoading = false;
            state.isAuthorize = false;
            state.isAuthErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [getPhoneCodeAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getPhoneCodeAsync.fulfilled]: (state, action) => {
            console.log('getPhoneCode', action.payload);
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isAuthErrors = true;
                state.errorDescription = action.payload.errorDescription;
                state.errorType = action.payload.error;
                state.isAuthorize = false;
            } else {
                state.errorDescription = '';
                state.errorType = '';
                state.isSendCode = true;
            }
            state.isLoading = false;
        },
        [getPhoneCodeAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.isAuthorize = false;
            state.isAuthErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [saveUserInfo.pending]: (state) => {
            state.isSavingUser = true;
        },
        [saveUserInfo.fulfilled]: (state, action) => {
            if (action.payload === undefined) {
                state.isSavingError = true;
                state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
            } else if (action.payload.error !== undefined && action.payload.error != null) {
                state.isSavingError = true;
                state.errorDescription = action.payload.errorDescription;
                console.error('saveUserError', action.payload.errorDescription);
            } else {
                state.user = action.payload.result;
                state.isSavingError = false;
                //dispatch(addNotification(action.payload.result));
            }
            state.isSavingUser = false;
        },
        [saveUserInfo.rejected]: (state, action) => {
            state.isSavingUser = false;
            state.isSavingError = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const { setIsLoading, setIsAuthorize, logoutUser } = currentUserSlice.actions;

export const selectUser = (state) => state.currentUser.user;
export const selectIsAuthorize = (state) => state.currentUser.isAuthorize;
export const selectIsLoading = (state) => state.currentUser.isLoading;

export default currentUserSlice.reducer;
