import { useEffect, useState } from 'react';
import Paginator from '../../../../components/Paginator';
import PermissionPage from '../../../../components/PermissionPage';
import { DateToString, UtcToLocal } from '../../../../helpers/DateHelpers';
import { Http } from '../../../../http';
import { saveAs } from 'file-saver';
import SkeletonTable from '../../../../components/skeleton/SkeletonTable';
import ThSortable from '../../../../components/table/ThSortable';
import FilterAccountSelector from '../../../accesslog/filters/FilterAccountSelector';
import LetOutModal from './LetOutModal';

const NightWatchPage = (props) => {
    const accountId = props.match.params.accountId;

    const [isLoading, setIsLoading] = useState(true);
    const [guests, setGuests] = useState([]);
    // Всё, что относится к пагинатору
    const [paginator, setPaginator] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    const [sort, setSort] = useState('');
    const [sortDest, setSortDest] = useState('asc');
    const [vehSort, setVehSort] = useState('');

    const [filterAccount, setFilterAccount] = useState('');

    const [isOpenLetOutModal, setIsOpenLetOutModal] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            await loadData();
            setIsLoading(false);
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [pageIndex, pageSize, vehSort, filterAccount]);

    const loadData = async () => {
        const getResult = await Http.get(
            `api/guests/auto/control?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&sort=${vehSort}&subAccountId=${filterAccount}`,
            true
        );

        setGuests(getResult.result);
        setPaginator(getResult.paginator);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const resetCar = async (id) => {
        const getResult = await Http.put(`api/guests/${id}/auto/reset`, true);
        await loadData();
    };

    const resetVehicle = async (id) => {
        const getResult = await Http.put(`api/vehicles/${id}/auto/reset`, true);
        await loadData();
    };

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setVehSort(colName + (dest === 'desc' ? '_desc' : ''));
    };

    const exportData = async () => {
        const getResult = await Http.getMedia(
            `api/guests/auto/control?accountId=${accountId}&pageIndex=1&pageSize=10000&export=true&subAccountId=${filterAccount}`
        );
        var blob = new Blob([getResult], {
            type: 'text/csv',
        });
        saveAs(blob, 'Ночной дозор.csv');
    };

    const handleDelete = (entity) => {
        setSelectedEntity(entity);
        setIsOpenLetOutModal(true);
    };

    const closeDeleteModal = (isReload) => {
        setIsOpenLetOutModal(false);
        if (isReload) {
            loadData();
        }
    };

    return (
        <>
            <LetOutModal entity={selectedEntity} isOpen={isOpenLetOutModal} closeHandle={closeDeleteModal} />
            <PermissionPage accountId={accountId} roles={['guest_car_panel']}>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='mb-5'>Ночной дозор</h1>
                        <div className='row mb-3'>
                            <div className='col text-start'>
                                <div style={{ display: 'inline-block' }}>Выберите аккаунт</div>
                                <div style={{ display: 'inline-block', marginLeft: 5, width: 250 }}>
                                    <FilterAccountSelector callback={(value) => setFilterAccount(value)} />
                                </div>
                            </div>
                            <div className='col text-end'>
                                <button className='btn av-btn-primary ms-2' onClick={() => exportData()}>
                                    Экспорт
                                </button>
                            </div>
                        </div>
                        <div className='card border'>
                            <div className='table-responsive'>
                                <SkeletonTable
                                    cols={['Аккаунт', 'Посетитель', 'Гос. номер', 'Заезд', '']}
                                    rowsCount={15}
                                    isLoading={isLoading}
                                    classTable='table-striped'
                                >
                                    <table className='table mb-0'>
                                        <thead>
                                            <tr className='fw-bold av-text-gray-500 border-bottom'>
                                                <ThSortable
                                                    className='border-bottom ps-6'
                                                    colName='account'
                                                    colTitle='Аккаунт'
                                                    colChanged={(name) => columnFilter(name)}
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className='border-bottom'
                                                    colName='guest'
                                                    colTitle='Имя'
                                                    colChanged={(name) => columnFilter(name)}
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className='border-bottom'
                                                    colName='number'
                                                    colTitle='Гос. номер'
                                                    colChanged={(name) => columnFilter(name)}
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className='border-bottom'
                                                    colName='date'
                                                    colTitle='Заезд'
                                                    colChanged={(name) => columnFilter(name)}
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className='border-bottom'
                                                    colName='type'
                                                    colTitle='Тип'
                                                    colChanged={(name) => columnFilter(name)}
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <th className='border-bottom'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {guests?.map((item) => (
                                                <tr key={item.entityId} className='cursor-pointer'>
                                                    <td className='ps-6 av-text-gray-500 align-middle'>
                                                        {item.account != null ? item.account.title : ''}
                                                    </td>
                                                    <td className='av-text-gray-500 align-middle'>{item.entityName}</td>
                                                    <td className='fw-bolder align-middle'>
                                                        {item.carNumber + ' ' + item.carRegion}
                                                    </td>
                                                    <td className='align-middle'>
                                                        {DateToString(UtcToLocal(item.dateIn), true)}
                                                    </td>
                                                    <td className='align-middle'>
                                                        {item.entityType === 'guest' ? 'Посетитель' : 'Транспорт'}
                                                    </td>
                                                    <td className='align-middle'>
                                                        <button
                                                            className='btn av-btn-primary btn-sm'
                                                            onClick={() => {
                                                                handleDelete(item);
                                                            }}
                                                        >
                                                            Удалить
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </SkeletonTable>
                            </div>
                            <div className='card-body py-3 px-5'>
                                <Paginator
                                    onPageChange={changePage}
                                    onPageSizeChange={changePageSize}
                                    pageCount={paginator.totalPages}
                                    pageSizeArray={[50, 100, 500]}
                                    pageSize={pageSize}
                                    countCurrent={guests.length}
                                    countTotal={paginator.totalCount}
                                    forcePage={forcePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionPage>
        </>
    );
};

export default NightWatchPage;
